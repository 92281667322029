import { Remote, wrap } from 'comlink';

type HandleTiffWorker = typeof import('./handleTiffWorker');

export default class TiffWorker {
  private static worker?: Worker;
  private static workerInstance?: Remote<HandleTiffWorker>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  private static getWorker() {
    if (!this.worker) {
      this.worker = new Worker(new URL('handleTiffWorker', import.meta.url), {
        type: 'module',
      });
    }

    return this.worker;
  }

  private static getWorkerInstance() {
    if (!this.workerInstance) {
      this.workerInstance = wrap<HandleTiffWorker>(this.getWorker());
    }

    return this.workerInstance;
  }

  static async handleBuffer(tiffBuffer: ArrayBuffer) {
    return this.getWorkerInstance().handleTiffBuffer(tiffBuffer);
  }

  static terminateWorker() {
    this.worker?.terminate();
  }
}
