import React, { useCallback, useEffect, useState } from 'react';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import PdfViewer from './PDFViewer';
import {
  calculateParentOrigin,
  sendOriginViewerLoaded,
  sendOriginViewerReady,
  sendOriginCloseRequest,
  sendOriginError,
} from './iframe-logic';
import { GlobalContext } from './context/GlobalContext';
import { handleDocumentBuffer } from './logic/file-conversion';
import { documentViewerLogger as logger } from './logic/logger';
import {
  DocumentData,
  LoadDocumentResponse,
  TiffLogic,
  ErrorMessages,
  ErrorTypes,
} from './logic/types';

import './App.less';
import '@getvim/atomic-ui/assets/styles/main.less';

const App: React.FC = () => {
  const [documentBuffer, setDocumentBuffer] = useState<ArrayBuffer>();
  const [documentMetadata, setDocumentMetadata] = useState<Omit<DocumentData, 'buffer'>>();

  const [document, setDocument] = useState<string | Uint8Array>();
  const [documentHighlights, setDocumentHighlights] = useState<HighlightArea[]>();
  const [tiffLogic, setTiffLogic] = useState<TiffLogic>();

  const onViewerClose = useCallback(() => {
    setDocumentBuffer(undefined);
    setDocument(undefined);
    setDocumentHighlights(undefined);
    setDocumentMetadata(undefined);
    sendOriginCloseRequest();
  }, []);

  const onViewerReady = useCallback(() => {
    sendOriginViewerReady({
      tiffLogic: tiffLogic,
    });
  }, [tiffLogic]);

  const onViewerError = useCallback((error) => {
    logger.error('Something went wrong while loading a document', { error });
    sendOriginError(error);
  }, []);

  useEffect(() => {
    const listener = (message) => {
      logger.info('Received message from parent', { message });
      if (message.origin !== calculateParentOrigin()) {
        return;
      }
      const { dataReady, buffer, userData, ...documentData } = message.data ?? {};
      if (dataReady) {
        setDocumentBuffer(buffer);
        setDocumentMetadata(documentData);
      }
    };

    window.addEventListener('message', listener);
    sendOriginViewerLoaded();
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  useEffect(() => {
    const handleConvert = async () => {
      try {
        if (documentBuffer) {
          const { documentExtension, highlights } = documentMetadata ?? {};

          const { success, document, tiffMetadata, error }: LoadDocumentResponse =
            await handleDocumentBuffer(documentBuffer, documentExtension!);

          if (success) {
            setDocumentHighlights(highlights);
            setDocument(document);
            setTiffLogic(tiffMetadata?.conversionLogic);
          } else {
            const { errorType, errorMessage, extraParams } = error ?? {};
            logger.error('Error while converting document from buffer', {
              errorMessage,
              extraParams,
            });
            sendOriginError({
              name: errorType,
              message: errorMessage,
              extraParams,
            });
          }
        }
      } catch (error) {
        logger.error(ErrorMessages.UNKNOWN_GENERAL, error);
        sendOriginError({
          name: ErrorTypes.UNHANDLED_CONVERT_ERROR,
          message: ErrorMessages.UNKNOWN_GENERAL,
          extraParams: {
            error,
          },
        });
      }
    };
    handleConvert();
  }, [documentBuffer, documentMetadata]);

  if (!document) return null;

  return (
    <GlobalContext.Provider
      value={{
        featureFlags: {},
      }}
    >
      <PdfViewer
        document={document}
        highlights={documentHighlights}
        onViewerReady={onViewerReady}
        onViewerClose={onViewerClose}
        onViewerError={onViewerError}
      />
    </GlobalContext.Provider>
  );
};

export default App;
