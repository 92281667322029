import React, { ReactElement } from 'react';
import {
  Viewer,
  Worker,
  Plugin,
  DocumentLoadEvent,
  SpecialZoomLevel,
} from '@react-pdf-viewer/core';
import { DefaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { HighlightArea, HighlightPlugin } from '@react-pdf-viewer/highlight';
import { getLayoutPlugin } from './components/layout';
import { getHighlightPlugin } from './components/highlighter/Highlighter';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import './PDFViewer.less';

const specialZooms = new Set([
  SpecialZoomLevel.ActualSize,
  SpecialZoomLevel.PageFit,
  SpecialZoomLevel.PageWidth,
]);

const onRenderPage = (renderPageProps) => {
  const annotationLayerChildrens: React.ReactElement[] = [];
  React.Children.forEach(renderPageProps.annotationLayer.children, (child) => {
    if ('onJumpToDest' in child.props) {
      const reactChild = React.cloneElement(child as ReactElement, {
        onJumpToDest: (jumpToDestProps) => {
          if (
            jumpToDestProps.pageIndex !== 0 ||
            (jumpToDestProps.pageIndex === 0 &&
              'leftOffset' in jumpToDestProps &&
              jumpToDestProps.leftOffset() === 0)
          ) {
            child.props.onJumpToDest({
              ...jumpToDestProps,
              pageIndex:
                specialZooms.has(jumpToDestProps.scaleTo) && jumpToDestProps.pageIndex !== 0
                  ? jumpToDestProps.pageIndex - 1
                  : jumpToDestProps.pageIndex,
              scaleTo: null,
            });
          }
        },
      });

      annotationLayerChildrens.push(reactChild);
    }
  });

  return (
    <>
      {renderPageProps.canvasLayer.children}
      {renderPageProps.textLayer.children}
      {annotationLayerChildrens}
    </>
  );
};

const PdfViewer: React.FC<{
  document: string | Uint8Array;
  highlights?: HighlightArea[];
  onViewerReady(): void;
  onViewerClose(): void;
  onViewerError(error): void;
}> = ({ document, highlights, onViewerReady, onViewerClose, onViewerError }) => {
  const plugins: Plugin[] = [];

  let highlightPluginInstance: HighlightPlugin | undefined;
  if (highlights?.length) {
    highlightPluginInstance = getHighlightPlugin(highlights);
    plugins.push(highlightPluginInstance);
  }

  const { jumpToHighlightArea } = highlightPluginInstance ?? {};
  const layoutPlugin: DefaultLayoutPlugin = getLayoutPlugin({
    onViewerClose,
    highlightSideBar: { jumpToHighlightArea, highlights },
  });
  plugins.push(layoutPlugin);

  const onDocumentLoad = (_event: DocumentLoadEvent) => {
    onViewerReady();
    if (highlights?.[0] && jumpToHighlightArea) {
      jumpToHighlightArea(highlights[0]);
      if (highlights.length > 1) {
        layoutPlugin.activateTab(1);
      }
    }
  };

  return (
    <div>
      <Worker workerUrl="./assets/pdf.worker.min.js">
        <Viewer
          fileUrl={document}
          plugins={plugins}
          renderPage={onRenderPage}
          onDocumentLoad={onDocumentLoad}
          renderLoader={() => <></>} // Disable the loader, the main app is handling it
          renderError={(error) => {
            onViewerError(error);
            return <></>;
          }}
        />
      </Worker>
    </div>
  );
};

export default PdfViewer;
