import React from 'react';
import {
  defaultLayoutPlugin,
  SidebarTab,
  DefaultLayoutPlugin,
} from '@react-pdf-viewer/default-layout';
import renderHighlightsSidebar, { HighlightSidebarProps } from './HighlightsSidebar';
import getRenderToolbar from './Toolbar';

type GetLayoutPluginProps = {
  onViewerClose: () => void;
  highlightSideBar: Partial<HighlightSidebarProps>;
};

export const getLayoutPlugin = ({
  onViewerClose,
  highlightSideBar,
}: GetLayoutPluginProps): DefaultLayoutPlugin => {
  return defaultLayoutPlugin({
    renderToolbar: getRenderToolbar(onViewerClose),
    sidebarTabs: getSidebarTabs({
      highlightSideBar,
    }),
  });
};

type GetSidebarTabsProps = Omit<GetLayoutPluginProps, 'onViewerClose'>;

const getSidebarTabs = ({ highlightSideBar }: GetSidebarTabsProps) => {
  const { jumpToHighlightArea, highlights } = highlightSideBar;

  const sideBarTabs = (defaultTabs: SidebarTab[]) => {
    const [pageNavigationSidebar] = defaultTabs;

    const tabsToPresent: SidebarTab[] = [pageNavigationSidebar];

    if (highlights?.length && jumpToHighlightArea) {
      tabsToPresent.push({
        content: renderHighlightsSidebar({ jumpToHighlightArea, highlights }),
        icon: <i className="icon-pencil" style={{ fontSize: '18px' }} />,
        title: 'Highlights',
      });
    }

    return tabsToPresent;
  };

  return sideBarTabs;
};
