import React from 'react';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import './HighlightsSidebar.less';

export type HighlightSidebarProps = {
  jumpToHighlightArea: (area: HighlightArea) => void;
  highlights: HighlightArea[];
};

const renderHighlightsSidebar = ({ jumpToHighlightArea, highlights }: HighlightSidebarProps) => (
  <div className="highlight-sidebar">
    {highlights.map((highlight, idx) => {
      return (
        <div
          key={`highlight_area_${idx}`}
          className="highlight-sidebar-item"
          onClick={() => jumpToHighlightArea(highlight)}
        >
          <div className="highlight-sidebar-item-text">Highlight {idx + 1}</div>
          <i className="icon-left-arrow" />
        </div>
      );
    })}
  </div>
);

export default renderHighlightsSidebar;
