import React from 'react';
import {
  highlightPlugin,
  HighlightArea,
  RenderHighlightsProps,
  Trigger,
} from '@react-pdf-viewer/highlight';

export const getHighlightPlugin = (highlights: HighlightArea[]) => {
  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {highlights
        .filter((area) => area.pageIndex === props.pageIndex)
        .map((area, idx) => (
          <div
            key={idx}
            className="highlight-area"
            style={Object.assign(
              {},
              {
                background: 'yellow',
                opacity: 1,
                'mix-blend-mode': 'darken',
              },
              props.getCssProperties(area, props.rotation),
            )}
          />
        ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  return highlightPluginInstance;
};
