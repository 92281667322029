import { vimConnectClientLogger } from '@getvim/react-app-infra';
import { ScopedLogger, Team } from '@getvim/vim-connect-logger';

const moduleName = 'document-viewer';

export const documentViewerLogger: ScopedLogger = vimConnectClientLogger.scope(
  'Document Viewer Client',
  {
    moduleName,
    moduleAndScopeSameColor: true,
  },
  {
    team: Team.PatientInsights,
    moduleName,
  },
);
