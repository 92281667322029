import { calculateParentOrigin } from './calculateParentOrigin';
export { calculateParentOrigin } from './calculateParentOrigin';
import { TiffLogic } from '../logic/types';
import { documentViewerLogger as logger } from '../logic/logger';

export const sendOriginViewerLoaded = () => {
  const parentOrigin = calculateParentOrigin();
  logger.info('Sending viewer loaded message to parent', { parentOrigin });
  window.parent.postMessage({ viewerLoaded: true }, parentOrigin);
};

export const sendOriginViewerReady = ({ tiffLogic }: { tiffLogic?: TiffLogic }) => {
  const parentOrigin = calculateParentOrigin();
  logger.info('Sending viewer ready message to parent', { parentOrigin, tiffLogic });
  window.parent.postMessage({ viewerReady: true, tiffLogic }, parentOrigin);
};

export const sendOriginCloseRequest = () => {
  const parentOrigin = calculateParentOrigin();
  window.parent.postMessage({ closeViewer: true }, parentOrigin);
};

export const sendOriginError = (error) => {
  const parentOrigin = calculateParentOrigin();
  window.parent.postMessage({ viewerError: true, error }, parentOrigin);
};
