import React, { ReactElement } from 'react';
import { MinimalButton } from '@react-pdf-viewer/core';
import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

const getRenderToolbar = (onViewerClose) => {
  const DocumentViewerToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          ShowSearchPopover,
          ZoomOut,
          Zoom,
          ZoomIn,
          Download,
          Print,
          GoToPreviousPage,
          CurrentPageInput,
          NumberOfPages,
          GoToNextPage,
        } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <div style={{ padding: '0px 2px' }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomOut />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Zoom />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomIn />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding: '0px 2px', width: '2rem' }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: '4px' }}>
              of <NumberOfPages />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <Download />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Print />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <MinimalButton onClick={onViewerClose}>
                <i className="icon-x-2" />
              </MinimalButton>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  DocumentViewerToolbar.displayName = 'DocumentViewerToolbar';
  return DocumentViewerToolbar;
};

export default getRenderToolbar;
