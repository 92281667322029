import TiffWorker from './handleTiff/tiffWorker';
import {
  SupportedFileExtension,
  LoadDocumentResponse,
  TiffWorkerResponse,
  ErrorMessages,
  ErrorTypes,
} from '../types';

export const handleDocumentBuffer = async (
  buffer: ArrayBuffer,
  fileExtension: SupportedFileExtension,
): Promise<LoadDocumentResponse> => {
  try {
    if (!buffer) {
      return {
        success: false,
        fileExtension,
        error: {
          errorType: ErrorTypes.CONVERT_BUFFER_ERROR,
          errorMessage: ErrorMessages.INVALID_BUFFER,
        },
      };
    }

    switch (fileExtension) {
      case SupportedFileExtension.PDF:
        return {
          success: true,
          document: new Uint8Array(buffer),
          fileExtension: SupportedFileExtension.PDF,
        };

      case SupportedFileExtension.TIF:
      case SupportedFileExtension.TIFF: {
        try {
          const tiffWorkerResponse: TiffWorkerResponse = await TiffWorker.handleBuffer(buffer);

          const {
            success,
            tiffDataArray,
            conversionLogic,
            errorMessage: workerError,
          } = tiffWorkerResponse;

          let error: LoadDocumentResponse['error'];
          if (!success) {
            error = {
              errorType: ErrorTypes.CONVERT_BUFFER_ERROR,
              errorMessage: ErrorMessages.TIFF_CONVERSION,
              extraParams: {
                workerError,
              },
            };
          }

          return {
            success,
            document: tiffDataArray,
            fileExtension,
            tiffMetadata: {
              conversionLogic,
            },
            error,
          };
        } catch (error) {
          return {
            success: false,
            fileExtension,
            error: {
              errorType: ErrorTypes.CONVERT_BUFFER_ERROR,
              errorMessage: ErrorMessages.UNKNOWN_TIFF_CONVERSION,
              extraParams: {
                error,
              },
            },
          };
        }
      }

      default:
        return {
          success: false,
          fileExtension,
          error: {
            errorType: ErrorTypes.CONVERT_BUFFER_ERROR,
            errorMessage: ErrorMessages.UNSUPPORTED_FILE,
            extraParams: {
              fileExtension,
            },
          },
        };
    }
  } catch (error) {
    return {
      success: false,
      fileExtension,
      error: {
        errorType: ErrorTypes.CONVERT_BUFFER_ERROR,
        errorMessage: ErrorMessages.UNKNOWN_GENERAL,
        extraParams: {
          error,
        },
      },
    };
  } finally {
    TiffWorker.terminateWorker();
  }
};
