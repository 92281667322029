import { documentViewerLogger } from '../logic/logger';
import { isEmpty } from 'lodash-es';

const DIAGNOSIS_GAPS_ORIGIN = 'diagnosis-gaps';

export const calculateParentOrigin = () => {
  const env = (window as any).$vim_environment?.APP_ENV;
  const queryParams = new URLSearchParams(window.location.search);
  let originServiceName = queryParams.get('originApp');

  if (isEmpty(originServiceName)) {
    originServiceName = DIAGNOSIS_GAPS_ORIGIN;
  }

  switch (env) {
    case 'local':
    case 'dev':
      return `https://${originServiceName}.dev.devim.io`;
    case 'staging':
    case 'demo':
      return `https://${originServiceName}.${env}.getvim.com`;
    case 'prod':
    default:
      return `https://${originServiceName}.getvim.com`;
  }
};
