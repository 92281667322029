import React from 'react';
import ReactDOM from 'react-dom/client';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { featureFlagsClient } from './logic/featureFlags';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
      <App />
    </FeatureFlagProvider>
  </React.StrictMode>,
);
