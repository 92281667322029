import { HighlightArea } from '@react-pdf-viewer/highlight';

export type DocumentData = {
  buffer?: ArrayBuffer;
  documentExtension?: SupportedFileExtension;
  highlights?: HighlightArea[];
  userData?: {
    organizationName?: string;
    userEmail?: string;
  };
};

export enum SupportedFileExtension {
  PDF = 'pdf',
  TIF = 'tif',
  TIFF = 'tiff',
  NA = 'N/A',
}

export type LoadDocumentResponse = {
  success: boolean;
  document?: string | Uint8Array;
  fileExtension: SupportedFileExtension;
  tiffMetadata?: {
    conversionLogic: TiffLogic;
    dataSize?: string;
  };
  error?: {
    errorType: ErrorTypes;
    errorMessage: ErrorMessages;
    extraParams?: object;
  };
};

export type TiffWorkerResponse = {
  success: boolean;
  conversionLogic: TiffLogic;
  tiffDataArray?: Uint8Array;
  tiffDataSize?: string;
  errorMessage?: string;
};

export enum TiffLogic {
  GENERAL = 'GENERAL',
  LOW_DEPTH = 'LOW_DEPTH',
}

export const UNSUPPORTED_BIT_DEPTH = 'Unsupported bit depth';

export enum ErrorTypes {
  UNHANDLED_CONVERT_ERROR = 'UnhandledConvertError',
  CONVERT_URL_ERROR = 'ConvertFileUrlError',
  CONVERT_BUFFER_ERROR = 'ConvertFileBufferError',
}

export enum ErrorMessages {
  INVALID_URL = 'Invalid URL provided',
  INVALID_BUFFER = 'Invalid buffer provided',
  UNSUPPORTED_FILE = 'Unsupported file extension',
  TIFF_CONVERSION = 'Error converting TIFF to PDF in worker',
  UNKNOWN_TIFF_CONVERSION = 'Unknown error in TIFF worker',
  UNKNOWN_GENERAL = 'Unknown error while converting document',
}
